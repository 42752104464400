var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.hideHeader
      ? _c(
          "h3",
          [
            _vm._v(
              "\n    Filing Service — " + _vm._s(_vm.product.name) + "\n    "
            ),
            _c("ach-warning", { attrs: { "cart-item": _vm.product } }),
          ],
          1
        )
      : _vm._e(),
    _vm.ineligibleToOrderProduct(_vm.product) && _vm.loaded
      ? _c("div", [
          _vm._v("\n    " + _vm._s(_vm.ineligibleToOrderText) + "\n  "),
        ])
      : _c(
          "div",
          [
            _vm.loaded
              ? _c("filing-options", {
                  attrs: {
                    "submission-label": "Add to Cart",
                    loading: _vm.loading,
                    product: _vm.product,
                    company: _vm.company,
                    "saved-items": _vm.cartItems,
                    "disable-domestic-form": _vm.isStagelineRoute,
                  },
                  on: {
                    submit: _vm.addFilingToCart,
                    "remove-by-id": _vm.removeFromCart,
                    "remove-by-jurisdiction": _vm.removeCartItemForJurisdiction,
                    "remove-by-local-jurisdiction":
                      _vm.removeCartItemForJurisdiction,
                  },
                })
              : _vm._e(),
            !_vm.loaded ? _c("ct-centered-spinner") : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }